import { useRouter } from 'next/router';

export default function useSearchParams(): URLSearchParams;
export default function useSearchParams(options: { asString: true }): string;
export default function useSearchParams(options?: { asString: boolean }): URLSearchParams | string {
  const { asPath } = useRouter();
  const search = typeof window !== 'undefined' ? window.location.search : asPath.split('?')[1];
  const params = new URLSearchParams(search);

  if (options?.asString) {
    const searchParamsString = params.toString();
    const queryString = searchParamsString?.length ? `?${searchParamsString}` : '';
    return queryString;
  }

  return params;
}
