import Meta from './meta';
import { setDayjsLocale } from '../lib/dayjs';
import { useRouter } from 'next/router';
import { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from 'react';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';
import WrapperWithBackground from './_Theme/WrapperWithBackground';
import { twMerge } from 'tailwind-merge';
import useBookingParams, { useWatchBookingParams } from '../hooks/useBookingParams';
import { useDispatch } from 'react-redux';
import {
  setIsApp,
  setAppVersion,
  setName,
  setPhone,
  setEmail,
  setInTheCircus,
  setLang,
  setRef,
} from '../services/paramsSlice';
import { setupGTM } from '../lib/utils/gtmTag';
import { useGetCountryCode, useGetPlaceId } from '../services/s3Api';

interface Props {
  children?: React.ReactNode;
  mainProps?: DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
  variant?: 'default' | 'filled';
  color?: string;
}

export default function Layout(props: Props) {
  const { children, variant = 'default', color = 'redOne', mainProps } = props;
  const router = useRouter();
  const { locale, route } = router;
  const dispatch = useDispatch();
  const { params, setPlaceId, setZone } = useBookingParams();
  const placeId = useGetPlaceId(locale);

  useEffect(() => {
    setDayjsLocale(locale);
    dispatch(setLang(locale));
  }, [locale]);

  // Handle back button and back navigation state for iOS and Android apps. Using a javascript interface.
  useEffect(() => {
    if (typeof window !== 'undefined' && typeof history !== 'undefined') {
      const backButtonEnabled = history.length > 1 && route !== '/venue/[venueId]/new-reservation';
      // @ts-ignore
      if (window?.webkit?.messageHandlers?.bookTable?.postMessage) {
        // @ts-ignore
        window?.webkit?.messageHandlers?.bookTable?.postMessage({
          backButtonEnabled,
        });
      }
      // @ts-ignore
      if (window?.BookTable?.setBackButtonEnabled) {
        // @ts-ignore
        window?.BookTable?.setBackButtonEnabled(backButtonEnabled);
      }
    }
  }, [route]);

  useEffect(() => {
    const { query } = router;

    // Mapping of query parameters to dispatch actions
    const queryActions = {
      app: (value: string | string[]) =>
        dispatch(setIsApp(Array.isArray(value) ? value[0] === 'true' : value === 'true')),
      appVersion: (value: string | string[]) =>
        dispatch(setAppVersion(Array.isArray(value) ? value[0] : value)),
      phone: (value: string | string[]) =>
        dispatch(setPhone(Array.isArray(value) ? value[0] : value)),
      name: (value: string | string[]) =>
        dispatch(setName(Array.isArray(value) ? value[0] : value)),
      email: (value: string | string[]) =>
        dispatch(setEmail(Array.isArray(value) ? value[0] : value)),
      inTheCircus: (value: string | string[]) =>
        dispatch(setInTheCircus(Array.isArray(value) ? value[0] === 'true' : value === 'true')),
      ref: (value: string | string[]) => dispatch(setRef(Array.isArray(value) ? value[0] : value)),
    };

    // Iterate over the query parameters and call the appropriate action
    Object.entries(query).forEach(([key, value]) => {
      if (queryActions[key]) {
        queryActions[key](value);
      }
    });
  }, [router.query, dispatch]);

  useEffect(() => {
    if (placeId) {
      setPlaceId(placeId);
    }
  }, [placeId]);

  return (
    <>
      <WrapperWithBackground>
        <Meta />
        <main
          {...mainProps}
          className={twMerge(
            mainProps?.className,
            variant === 'filled' ? `flex bg-${color}` : '',
            `flex flex-col ${
              params.isApp ? 'min-h-screen' : 'min-h-[100dvh]'
            } overflow-y-hidden w-full`,
          )}
        >
          {children}
          <Analytics />
          <SpeedInsights />
        </main>
      </WrapperWithBackground>
    </>
  );
}
