import Image from 'next/image';
import PinchosImage from '../../../public/assets/images/pinchos-background.jpg';
import { ReactNode } from 'react';
import { useGetClientInfo } from '../../../hooks/useGetClientInfo';
import useBookingParams from '../../../hooks/useBookingParams';

interface Props {
  children: ReactNode;
}

export default function WrapperWithBackground({ children }: Props) {
  const { params } = useBookingParams();
  return (
    <>
      <div className="fixed top-0 left-0 right-0 -z-40 flex justify-center">
        <div className={`w-screen ${params.isApp ? 'min-h-screen' : 'min-h-[100dvh]'} relative `}>
          <Image
            src={PinchosImage}
            alt="Pinchos"
            fill
            className="object-cover blur-[2px] !-top-20"
            priority
          />
        </div>
        <div className={`${params.isApp ? 'min-h-screen' : 'min-h-[100vh]'} bg-black opacity-80 fixed top-0 left-0 right-0`} />
      </div>
      {children}
    </>
  );
}
